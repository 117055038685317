<template>
    <div class="casePage">
        <headComp></headComp>
        <breadComp></breadComp>

        <div class="centerWrapper">
            <div class="tabs">
                <div class="tabList">
                    <div :class="{'item':1,'active':typeId==item.value}" v-for="(item,i) in typeList" :key="i" @click="clickTab(item,i)">
                        {{item.label}}
                        <span class="bottomLine"></span>
                    </div>
                </div>
            </div>
            <div class="list">
                <!-- <div class="item" v-for="i in 15" :key="i" @click="toDetail">
                    <img src="../assets/imgs/place34.png"/>
                    <div class="name">天然原木风打造舒缓的现代极简客厅内饰</div>
                    <div class="info">
                        <span>2023-12-15  16:02</span>
                        <span>
                            <img src="../assets/imgs/effect13.png"/>
                            1.7W
                        </span>
                    </div>
                </div> -->
                <div class="item" v-for="(item,i) in buyerShowList" :key="i" @click="toDetail(item)">
                    <!-- <img :src="item.images[0].url"/> -->
                    <div class="img">
                        <img v-if="item.images[0]" :src="item.images[0].url"/>
                        <div class="user" v-if="item.userInfo">
                            <img :src="item.userInfo.avatar"/>
                            <span>{{item.userInfo.nickName||item.userInfo.telephone}}</span>
                        </div>
                    </div>
                    <div class="name">{{item.content}}</div>
                    <div class="info">
                        <span>{{item.createTime}}</span>
                        <span>
                            <img src="../assets/imgs/effect13.png"/>
                            {{item.readCount}}
                        </span>
                    </div>
                </div>
                <div class="empty" v-show="buyerShowList.length==0">
                    <img src="../assets/imgs/place.png" />
                    <div>暂无案例</div>
                </div>
            </div>
            
            <div class="pagi">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="totalNum">
                </el-pagination>
            </div>
        </div>

        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import {getBuyersShowTypeList,getBuyersShowList__} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp
    },
    data(){
        return{
            currentPage:1,
            pageSize:12,
            typeList:[],
            typeId:"",
            buyerShowList:[],
            totalNum:0,
        }
    },
    methods: {
        toDetail(item){
            this.$router.push({
                path:"/caseDetail",
                query:{buyersShowId:item.buyersShowId}
            })
        },
        handleSizeChange(val){
            this.pageSize=val;
            this.gainBuyerShowList();
        },
        handleCurrentChange(val){
            this.currentPage=val;
            this.gainBuyerShowList();
        },
        clickTab(item){
            this.typeId=item.value;
            this.gainBuyerShowList();
        },
        // 获取买家秀分类列表
        gainBuyerShowType(){
            getBuyersShowTypeList().then(res=>{
                // console.log("买家秀分类列表",res);
                if(res.code==200){
                    this.typeList=res.data;
                    this.typeId=this.typeList[0].value;
                }else{
                    this.$message(res.msg||res.message)
                }
                this.gainBuyerShowList();
            })
        },
        // 获取买家秀列表
        gainBuyerShowList(){
            getBuyersShowList__({
                page:this.currentPage,
                size:this.pageSize,
                goodsTypeId:this.typeId,
            }).then(res=>{
                // console.log("买家秀列表",res);
                if(res.code==200){
                    this.buyerShowList=res.data.rows;
                    this.totalNum=Number(res.data.total);
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        }
    },
    created() {
        this.gainBuyerShowType();
    },
}
</script>

<style lang="less" scoped>
.casePage{
    .tabs{
        margin-top: 50px;
        height: 50px;
        // background: rgba(37, 126, 93, 1);
        padding: 0 20px;
        .tabList{
            height: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(219, 219, 219, 1);
            padding-bottom: 5px;
            .item{
                height: 100%;
                margin-right: 89px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.5);
                color: rgba(128, 128, 128, 1);
                cursor: pointer;
                .bottomLine{
                    position: absolute;
                    left: 50%;
                    bottom: 5px;
                    height: 4px;
                    width: 50%;
                    border-radius: 10px;
                    background: rgba(255, 255, 255, 1);
                    background: rgba(37, 126, 93, 1);
                    transform: translateX(-50%);
                    opacity: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.active{
                    font-size: 18px;
                    font-weight: 500;
                    color: rgba(255, 255, 255, 1);
                    color: rgba(37, 126, 93, 1);
                    .bottomLine{
                        opacity: 1;
                    }
                }
            }
        }
    }
    .list{
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 460px;
            margin-right: 30px;
            margin-right: 45px;
            margin-bottom: 48px;
            &:nth-child(3n){
                margin-right: 0;
            }
            cursor: pointer;
            &:hover{
                .img>img{
                    width: calc(100% + 20px);
                    height: calc(100% + 20px);
                }
            }
            &>.img{
                width: 100%;
                height: 370px;
                border-radius: 10px;
                overflow: hidden;
                position: relative;
                &>img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    transition: all 0.3s;
                    object-fit: cover;
                }
                .user{
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    // background: rgba(255, 255, 255, 0.3);
                    width: 100%;
                    height: 82px;
                    border-radius: 0px 0px 10px 10px;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
                    display: flex;
                    align-items: center;
                    padding: 0 15px;
                    &>img{
                        width: 46px;
                        height: 46px;
                        border-radius: 50%;
                    }
                    &>span{
                        margin-left: 10px;
                        font-size: 20px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }
            .name{
                margin-top: 20px;
                font-size: 20px;
                font-weight: 700;
                // line-height: 37.65px;
                // height: 74px;
                line-height: 30px;
                height: 60px;
                color: rgba(56, 56, 56, 1);
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .info{
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 20px;
                font-weight: 500;
                color: rgba(56, 56, 56, 1);
                &>span:nth-child(2){
                    display: flex;
                    align-items: center;
                    img{
                        margin-right: 4px;
                        width: 28px;
                        height: 21px;
                    }
                }
            }
        }
        .empty{
            width: 100%;
            padding: 50px 0;
            // display: flex;
            // justify-content: center;
            text-align: center;
            &>img{
                width: 200px;
                margin-bottom: 10px;
            }
        }
    }
    .pagi{
        margin-top: 130px;
        text-align: center;
        /deep/.btn-next , /deep/.btn-prev{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
        }
        /deep/ .number , /deep/.el-icon-more, /deep/.btn-quickprev, /deep/.btn-quicknext{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
            &.active{
                background-color: rgba(35, 125, 92, 1)!important;
            }
        }
        /deep/.el-pagination__jump{
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            font-weight: 500;
            columns: rgba(0, 0, 0, 1);
            .el-pagination__editor{
                height: 46px;
                .el-input__inner{
                    height: 46px;
                }
            } 
        }
    }
}
</style>